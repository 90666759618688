<template>
    <v-row no-gutters>
        <v-col cols="12">
            <RealmBar :accountId="$route.params.accountId" :realm="realm" class="mb-6" v-if="realm"></RealmBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <v-row justify="center" class="py-5 px-10" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card class="pa-0 mt-0">
                        <v-toolbar dense flat color="indigo" dark>
                            <v-toolbar-title>BrandProfile</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <p class="mb-0 mt-2">
                                A BrandProfile is a set of assets that represent your brand or ways of communicating with your company. These assets include logos and other images, color palettes, verified domains, email and postal addresses, and links to your website and social media accounts.
                            </p>
                            <p class="mb-0 mt-2">
                                You can manage all your BrandProfiles at <a href="https://brandprofile.org" target="_blank">brandprofile.org</a>.
                            </p>

                            <template v-if="isViewReady">
                                <template v-if="brandprofile">
                                <p class="mb-0 pb-0 mt-6">
                                    {{ brandprofile }}
                                    <v-btn icon color="indigo" @click="startConnect">
                                        <font-awesome-icon :icon="['fas', 'pencil-alt']"></font-awesome-icon>
                                    </v-btn>
                                </p>
                                </template>

                                <template v-if="!brandprofile">
                                <p class="mb-0 pb-0 mt-6">
                                    <a href="#" @click="startConnect">Connect this realm to a BrandProfile</a>
                                </p>
                                </template>
                            </template>

                            <!-- service admin can just set the brandprofile -->
                            <template v-if="isPermitSystemAdmin">
                            <p class="text-overline mb-0 mt-8">BrandProfile (Service Administrator ONLY) <font-awesome-icon :icon="['fas', 'id-badge']" class="indigo--text text--darken-2"></font-awesome-icon></p>
                            <p class="mb-0 pb-0">
                                {{ brandprofile }}
                                <v-btn icon color="indigo" @click="dialogEditBrandProfile = true">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"></font-awesome-icon>
                                </v-btn>
                            </p>
                            </template>
                        </v-card-text>
                    </v-card>
                    <v-dialog v-model="dialogEditBrandProfile" max-width="600">
                        <v-card class="pa-0" max-width="600">
                            <v-toolbar dense flat color="white">
                                <v-toolbar-title class="indigo--text text--darken-2">Connect BrandProfile</v-toolbar-title>
                            </v-toolbar>
                            <!-- <v-divider class="mx-5 mb-10"></v-divider> -->
                            <v-card-text>
                                <p>Changes to the logo, color palette, and brand name defined in the BrandProfile will be automatically reflected in all your customer service website.</p>
                            </v-card-text>
                            <v-form @submit.prevent="editBrandProfileAlias" @keyup.enter.native="editBrandProfileAlias" class="px-5 mt-5">
                                <v-text-field
                                    ref="editableBrandProfileInput"
                                    v-model="editableBrandProfileAlias"
                                    label="BrandProfile Alias"
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-form>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn elevation="4" class="indigo white--text" @click="editBrandProfileAlias" :disabled="!isEditBrandProfileAliasFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditBrandProfile = false">Cancel</v-btn>
                                <v-spacer/>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState, mapGetters } from 'vuex';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import RealmBar from '@/components/RealmBar.vue';
// import TextLink from '@/components/TextLink.vue';

export default {
    components: {
        AccessDeniedOverlay,
        RealmBar,
        // TextLink,
    },
    data: () => ({
        realm: null,
        forbiddenError: false,
        brandprofile: null,
        error: null,
        isViewReady: false,

        // for service admin only, direct editing of brandprofile
        dialogEditBrandProfile: false,
        editableBrandProfileAlias: null,
        submitTimestamp: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryTextStyle: 'primaryTextStyle',
            primaryIconButtonStyle: 'primaryIconButtonStyle',
            isPermitSystemAdmin: 'isPermitSystemAdmin',
        }),
        isViewReady() {
            return this.realm !== null;
        },
        isEditBrandProfileAliasFormComplete() {
            return this.editableBrandProfileAlias;
        },
    },
    watch: {
        dialogEditBrandProfile(newValue) {
            if (newValue) {
                this.editableBrandProfileAlias = this.brandprofile;
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('editableBrandProfileInput'); }, 1);
                });
            }
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async loadRealm() {
            try {
                this.$store.commit('loading', { loadRealm: true });
                const result = await this.$client.account(this.$route.params.accountId).realm.get({ id: this.$route.params.realmId });
                console.log(`realm/dashboard.vue loadRealm result: ${JSON.stringify(result)}`);
                if (result) {
                    this.realm = result;
                    this.brandprofile = result.brandprofile ?? result.info?.brandprofile_alias;
                }
            } catch (err) {
                console.error('realm/dashboard.vue loadRealm failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load realm', message });
            } finally {
                this.$store.commit('loading', { loadRealm: false });
            }
        },
        /**
         * For service admin only - API enforces this.
         */
        async editBrandProfileAlias() {
            try {
                this.$store.commit('loading', { editBrandProfileAlias: true });
                if (this.editableBrandProfileAlias) {
                    const isEdited = await this.$client.account(this.$route.params.accountId).realm.edit({ id: this.$route.params.realmId }, { brandprofile_alias: this.editableBrandProfileAlias });
                    if (isEdited) {
                        this.realm.info ??= {};
                        this.realm.info.brandprofile_alias = this.editableBrandProfileAlias;
                        this.dialogEditBrandProfile = false;
                        this.$bus.$emit('snackbar', { type: 'success', message: 'Updated BrandProfile alias' });
                    } else {
                        this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to set custom BrandProfile alias' });
                    }
                } else {
                    const isEdited = await this.$client.account(this.$route.params.accountId).realm.edit({ id: this.$route.params.realmId }, { action: 'reset-brandprofile-alias' });
                    if (isEdited) {
                        this.realm.info ??= {};
                        this.realm.info.brandprofile_alias = null;
                        this.dialogEditBrandProfile = false;
                        this.$bus.$emit('snackbar', { type: 'success', message: 'Reset BrandProfile alias' });
                    } else {
                        this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to reset BrandProfile alias' });
                    }
                }
            } catch (err) {
                console.error('editBrandProfileAlias failed', err);
                this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to save BrandProfile alias' });
            } finally {
                this.$store.commit('loading', { editBrandProfileAlias: false });
            }
        },
        async startConnect() {
            try {
                this.error = false;
                this.$store.commit('loading', { startConnect: true });
                const response = await this.$client.accountRealm(this.$route.params.accountId, this.$route.params.realmId).brandProfilePartner.startConnect();
                console.log(`startConnect: response ${JSON.stringify(response)}`);
                if (response?.redirect) {
                    if (typeof window.location.push === 'function') {
                        window.location.push(response.redirect);
                    } else {
                        window.location.href = response.redirect;
                    }
                }
            } catch (err) {
                console.error('failed to start partner connect', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to start BrandProfile verification request' });
            } finally {
                this.$store.commit('loading', { startConnect: false });
            }
        },
        async verifyConnect(token) {
            try {
                this.error = false;
                this.$store.commit('loading', { verifyConnect: true });
                const response = await this.$client.accountRealm(this.$route.params.accountId, this.$route.params.realmId).brandProfilePartner.verifyConnect(token);
                console.log(`verifyConnect: response ${JSON.stringify(response)}`);
                if (response?.brandprofile) {
                    this.brandprofile = response.brandprofile;
                } else {
                    await this.loadRealm();
                }
            } catch (err) {
                console.error('failed to verify partner connect', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to complete BrandProfile verification request' });
            } finally {
                this.$store.commit('loading', { verifyConnect: false });
            }
        },
        async init() {
            try {
                if (this.$route.query.token) {
                    await this.verifyConnect(this.$route.query.token);
                    const query = { ...this.$route.query, t: Date.now() };
                    delete query.token;
                    this.$router.replace({ name: 'realm-edit-brandprofile', params: { accountId: this.$route.params.accountId, realmId: this.$route.params.realmId }, query });
                } else {
                    await this.loadRealm();
                }
            } catch (err) {
                console.log('init failed', err);
            } finally {
                this.isViewReady = true;
            }
        },
    },
    mounted() {
        this.loadRealm();
        this.init();
    },
};
</script>
